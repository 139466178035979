// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-dbc9736c]{font-family:\"Open Sans\"}.font-header[data-v-dbc9736c]{font-family:\"Hero\"}.card-rating[data-v-dbc9736c]{display:flex;flex-wrap:wrap;justify-content:center;align-items:center;width:100%}.card-rating__score[data-v-dbc9736c]{font-family:\"Hero\"}.card-rating.sm .card-rating__score[data-v-dbc9736c]{font-size:20px;line-height:26px}.card-rating.sm .card-rating__star[data-v-dbc9736c]{width:20px;height:20px}.card-rating.md .card-rating__score[data-v-dbc9736c]{font-size:26px;line-height:32px}.card-rating.md .card-rating__star[data-v-dbc9736c]{width:25px;height:25px}.card-rating.lg .card-rating__score[data-v-dbc9736c]{font-size:32px;line-height:38px}.card-rating.lg .card-rating__star[data-v-dbc9736c]{width:35px;height:35px}.card-rating.left[data-v-dbc9736c]{justify-content:flex-start}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
