<template>
    <div
        :class="[size, align]"
        class="card-rating"
    >
        <img
            v-for="i in getCardStarsCount"
            :key="`rated${i}`"
            src="/star.webp"
            alt="Rating"
            class="card-rating__star"
            loading="lazy"
        >

        <img
            v-for="i in 5 - getCardStarsCount"
            :key="`unrated${i}`"
            src="/star-gray.webp"
            alt="Rating"
            class="card-rating__star"
            loading="lazy"
        >
    </div>
</template>

<script>
export default {
    props: {
        card: {
            type: Object,
            required: true,
            default: () => {}
        },
        rating: {
            type: Number,
            required: true,
            default: undefined
        },
        size: {
            type: String,
            required: false,
            default: 'md'
        },
        align: {
            type: String,
            required: false,
            default: 'center'
        }
    },
    computed: {
        getCardStarsCount() {
            if (this.card.data.overwrite_stars) {
                return Number(this.card.data.overwrite_stars);
            }
            return Number(Math.round(this.card.data.core_api.ratings.average_score));
        }
    }
};
</script>

<style lang="scss" scoped>
 .card-rating {
        @include flex;
        width: 100%;

        &__score { font-family: $fontHeader; }

        // Sizes
        &.sm {
            .card-rating__score {
                @include font-size(20px);
            }
            .card-rating__star {
                @include size(20px);
            }
        }
        &.md {
            .card-rating__score {
                @include font-size(26px);
            }
            .card-rating__star {
                @include size(25px);
            }
        }
        &.lg {
            .card-rating__score {
                @include font-size(32px);
            }
            .card-rating__star {
                @include size(35px);
            }
        }
    }

    .card-rating.left {
        justify-content: flex-start;
    }
</style>
