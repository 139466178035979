<template>
    <div
        v-if="model"
        class="data-boxes"
    >
        <div class="data-boxes__box font-text">
            <span class="head">{{ $translate('annual_fee', 'Årsavgift') }}</span>
            <div class="foot">
                <span v-html="model.yearlyCostHtml" />
            </div>
        </div>

        <div class="data-boxes__box mid font-text">
            <span class="head">{{ $translate('max_credit', 'Maxkredit') }}</span>
            <span class="foot">{{ maxCredit }}</span>
        </div>

        <div class="data-boxes__box font-text">
            <span class="head">{{ $translate('effective_interest', 'Effektiv ränta') }}</span>
            <span class="foot">{{ model.effectiveInterestString }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        model: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    computed: {
        maxCredit() {

            if (! this.model.isCredit) {
                return this.$translate('debit_card', 'Debitkort'); 
            }
            return this.model.getMaxCreditString(this.$translate('unlimited_max_credit', 'Obegränsad'));
        }
    }
};
</script>

<style lang="scss" scoped>
.data-boxes {
    @include grid(1, 0);
    @include device(pad) {
        @include grid(3, 30px);
    }

    &__box.mid {
        @include border((top,bottom));
    }

    &__box::v-deep {
        @include grid(2, 10px);
        @include spacing(padding,2,(top,bottom));
        @include device(pad) {
            @include grid(1, 15px);
        }

        span {
            @include font-size(14px,8px);

            &.head {
                color: #999;
                @include flex(start);
                .tooltip { margin-left: 10px; }
            }

            &.foot {
                font-weight: bold;
                text-transform: capitalize;
                @include device(mobile) { text-align: right; }
            }
        }

        .foot {
            font-weight: bold;
            @include flex(start);

            @include device(mobile) {
                justify-content: flex-end;
            }
        }

        &:nth-child(1),
        &:nth-child(2) {
            @include border(bottom);
            @include device(pad) {
                @include border(right);
            }
        }

        .standard-yearly-cost {
            display: inline;
            color: red;
            margin-left: 5px;
            text-decoration: line-through;
        }
    }
}
</style>
