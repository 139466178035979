<template>
    <ul>
        <li v-if="showHeader">
            {{ $translate('mobile_payments', 'Mobila betalningsmetoder') }}
        </li>
        <template v-if="mobilePayments.length">
            <li
                v-for="(payment, index) in mobilePayments"
                :key="index"
            >
                <div class="payment-container">
                    <div class="icon-wrapper">
                        <span>{{ payment.header }}</span>
                        <i class="payment-icons fa-2xl" :class="payment.icon" />
                    </div>
                    <i class="far" :class="payment.data ? 'fa-check fa-check1' : 'fa-xmark fa-clear'" />
                </div>
            </li>
        </template>
        <template v-else>
            <li class="no-data-text">
                <p>
                    {{ 
                        $translate('no_mobile_payments', '{card} erbjuder inga betalningsmetoder för tillfället.') 
                            .replace('{card}', model.title)
                    }}
                </p>
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        model: {
            type: Object,
            required: true
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        mobilePayments() {
            return [
                {
                    header: 'Google pay',
                    data: this.model.hasGooglePay,
                    icon: 'fa-brands fa-google-pay'
                },
                {
                    header: 'Apple pay',
                    data: this.model.hasApplePay,
                    icon: 'fa-brands fa-apple-pay'
                },
                {
                    header: 'Samsung pay',
                    data: this.model.hasSamsungPay,
                    icon: 'fa-kit fa-samsung-pay'
                },
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
    .no-data-text {
        padding-top: 0 !important;
        border: unset !important;
    }
    .payment-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
        gap: 0.75rem;
        span {
            padding-right: 0 !important;
        }
        .icon-wrapper {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            .payment-icons {
                margin-top: 0.25rem;
                font-size: 1.75em !important;
                line-height: .04167em;  
            }
        }
    }
</style>