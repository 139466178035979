<template>
    <div class="grid faq-grid">
        <question-and-answer
            v-for="(iterator, index) in faq"
            :key="index"
            :question="iterator.question"
            :answer="$prismic.asHtml(iterator.answer)"
        />
    </div>
</template>

<script>
import { QuestionAndAnswer } from '@swegaming-ab/vue-components';
export default {
    components: { QuestionAndAnswer },
    props: {
        faq: {
            type: Array,
            required: true
        }
    }
};
</script>