import { render, staticRenderFns } from "./FaqGrid.vue?vue&type=template&id=4b02c5c2&"
import script from "./FaqGrid.vue?vue&type=script&lang=js&"
export * from "./FaqGrid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports