// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-01e00bd1]{font-family:\"Open Sans\"}.font-header[data-v-01e00bd1]{font-family:\"Hero\"}.card-demands li[data-v-01e00bd1]{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:flex-start;font-size:13px;line-height:19px;box-sizing:border-box}@media screen and (max-width:600px){.card-demands li[data-v-01e00bd1]{padding-top:8px}}@media screen and (min-width:600px){.card-demands li[data-v-01e00bd1]{padding-top:8px;padding-top:10px}}@media screen and (max-width:600px){.card-demands li[data-v-01e00bd1]{padding-bottom:8px}}@media screen and (min-width:600px){.card-demands li[data-v-01e00bd1]{padding-bottom:8px;padding-bottom:10px}}.card-demands li span[data-v-01e00bd1]{flex:1}.card-demands li i[data-v-01e00bd1]{font-size:18px;margin-right:10px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
