<template>
    <ul class="card-demands">
        <li v-if="showHeader">
            {{ $translate('demands', 'Krav') }}
        </li>
        <li v-if="model.minAge">
            <i class="fa-solid fa-check-circle fa-check_circle" />
            <span>
                {{ 
                    $translate('min_age', 'Minst {minAge} år')
                        .replace('{minAge}', model.minAge)
                }}  
            </span>
        </li>
        <li v-if="minimumYearlyIncome">
            <i class="fa-solid fa-check-circle fa-check_circle" />
            <span>{{ minimumYearlyIncome }}</span>
        </li>
        <li v-if="!model.acceptsPaymentRemarks">
            <i class="fa-solid fa-check-circle fa-check_circle" />
            <span>{{ acceptsPaymentRemarks }}</span>
        </li>
        <li v-if="!model.minAge && !minimumYearlyIncome && model.acceptsPaymentRemarks">
            <span>
                {{ 
                    $translate('no_demands', '{card} ställer inga specifika krav.') 
                        .replace('{card}', model.title)
                }}
            </span>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        model: {
            type: Object,
            required: true,
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        minimumYearlyIncome() {
            const monthlyIncome = this.$format.currency(this.model.minimumYearlyIncome / 12);
            if (this.model.minimumYearlyIncome > 0) {
                return this.$translate('monthly_income_of_atleast_monthly_income', 'Inkomst på minst {monthlyIncome} /mån').replace('{monthlyIncome}', monthlyIncome);
            }
            else if (!this.model.acceptsNoIncome) {
                return this.$translate('employed', 'Anställning');
            }
        },
        acceptsPaymentRemarks() {
            return this.$translate('payment_remarks', 'Inga betalningsanmärkningar');
        }
    }
};
</script>

<style lang="scss" scoped>
    .card-demands {

        li {
            @include flex(start,start);
            @include font-size(13px, 6px);
            @include spacing(padding,2,(top,bottom));

            span { flex: 1; }
            i {
                font-size: 18px;
                margin-right: 10px;
            }
        }
    }
</style>
